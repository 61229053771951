import Vue from "vue";
import VueRouter from "vue-router";
import {currentUser} from "@/constants/config";

Vue.use(VueRouter);

let routes;

if (!currentUser || currentUser.role === 'ADMIN') {
  routes = [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "app" */ "./views/app"),
      redirect: "/app/index",

      children: [
        {
          path: "app/dashboard",
          component: () =>
            import(/* webpackChunkName: "dashboard" */ "./views/app/dashboard"),
        },
        {
          path: "app/pix",
          component: () =>
            import(/* webpackChunkName: "vendas" */ "./views/app/pix"),
        },
        {
          path: "app/vendas",
          component: () =>
            import(/* webpackChunkName: "vendas" */ "./views/app/vendas"),
        },
        {
          path: "app/recebidos",
          component: () =>
            import(/* webpackChunkName: "recebidos" */ "./views/app/recebidos"),
        },
        {
          path: "app/antecipacoes",
          component: () =>
            import(
              /* webpackChunkName: "antecipacoes" */ "./views/app/antecipacoes"
            ),
        },
        {
          path: "app/antecipacoes/relatorio",
          component: () =>
            import(
              /* webpackChunkName: "relatorio" */ "./views/app/antecipacoes/relatorio"
            ),
        },
        {
          path: "app/extrato",
          component: () =>
            import(/* webpackChunkName: "extrato" */ "./views/app/extrato"),
        },
        {
          path: "app/extrato/transferencia",
          component: () =>
            import(
              /* webpackChunkName: "extrato" */ "./views/app/extrato/transferencia"
            ),
        },
        {
          path: "app/logistica",
          component: () =>
            import(/* webpackChunkName: "logistica" */ "./views/app/logistica"),
        },
        {
          path: "app/flashlink",
          component: () =>
            import(/* webpackChunkName: "flashlink" */ "./views/app/flashlink"),
        },
        {
          path: "app/flashlink",
          component: () =>
            import(/* webpackChunkName: "flashlink" */ "./views/app/flashlink"),
          alias: ["app/flashlink", "app/safelink"],
        },
        {
          path: "app/simulacao",
          component: () =>
            import(/* webpackChunkName: "simulacao" */ "./views/app/simulacao"),
        },
        {
          path: "app/contestacao-vendas",
          component: () =>
            import(
              /* webpackChunkName: "contestacao-vendas" */ "./views/app/contestacao-vendas"
            ),
        },
        {
          path: "app/dashboard/meusDados",
          component: () =>
            import(
              /* webpackChunkName: "meusDados" */ "./views/app/dashboard/meusDados"
            ),
        },
        {
          path: "app/dashboard/terminais",
          component: () =>
            import(
              /* webpackChunkName: "terminais" */ "./views/app/dashboard/terminais"
            ),
        },
        {
          path: "app/dashboard/estabelecimentos",
          component: () =>
            import(
              /* webpackChunkName: "estabelecimentos" */ "./views/app/dashboard/estabelecimentos"
            ),
        },
        {
          path: "app/dashboard/estabelecimentos_associados",
          component: () =>
            import(
              /* webpackChunkName: "estabelecimentos_associados" */ "./views/app/dashboard/estabelecimentos_associados"
            ),
        },
        {
          path: "app/dashboard/senha",
          component: () =>
            import(
              /* webpackChunkName: "meusDados" */ "./views/app/dashboard/senha"
            ),
        },
        {
          path: "app/ecommerce",
          component: () =>
            import(
              /* webpackChunkName: "ecommerce" */ "./views/app/ecommerce/index"
            ),
        },
        {
          path: "app/manual-de-uso",
          component: () =>
            import(
              /* webpackChunkName: "manual-de-uso" */ "./views/app/manual-de-uso/index"
            ),
        },
        {
          path: "app/link-3ds",
          component: () =>
            import(
              /* webpackChunkName: "link-3ds" */ "./views/app/link3ds/index"
            ),
        },
        {
          path: "app/notificacoes",
          component: () =>
            import(
              /* webpackChunkName: "link-3ds" */ "./views/app/notificacoes/index"
            ),
        },
        {
          path: "app/link-boleto",
          component: () =>
            import(
              /* webpackChunkName: "link-3ds" */ "./views/app/link-boleto/index"
            ),
        },
        {
          path: "app/dashboard/vendedores",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/app/dashboard/vendedores"),
        },
      ],
    },
    {
      path: "/error",
      component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
    },
    {
      path: "/user",
      component: () => import(/* webpackChunkName: "user" */ "./views/user"),
      redirect: "/user/login",
      children: [
        {
          path: "login",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/Login"),
        },
        {
          path: "register",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/Register"),
        },
        {
          path: "forgot-password",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/ForgotPassword"),
        },
      ],
    },
    {
      path: "*",
      component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
    },
  ];
} else if (currentUser.role === 'SELLER') {
  routes = [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "app" */ "./views/app"),
      redirect: "/app/index",

      children: [
        {
          path: "app/pix",
          component: () =>
            import(/* webpackChunkName: "vendas" */ "./views/app/pix"),
        },
        {
          path: "app/flashlink",
          component: () =>
            import(/* webpackChunkName: "flashlink" */ "./views/app/flashlink"),
        },
        {
          path: "app/flashlink",
          component: () =>
            import(/* webpackChunkName: "flashlink" */ "./views/app/flashlink"),
          alias: ["app/flashlink", "app/safelink"],
        },
        {
          path: "app/simulacao",
          component: () =>
            import(/* webpackChunkName: "simulacao" */ "./views/app/simulacao"),
        },
        {
          path: "app/dashboard/meusDados",
          component: () =>
            import(
              /* webpackChunkName: "meusDados" */ "./views/app/dashboard/meusDados"
              ),
        },
        {
          path: "app/dashboard/senha",
          component: () =>
            import(
              /* webpackChunkName: "meusDados" */ "./views/app/dashboard/senha"
              ),
        },
        {
          path: "app/link-boleto",
          component: () =>
            import(
              /* webpackChunkName: "link-3ds" */ "./views/app/link-boleto/index"
              ),
        },
      ],
    },
    {
      path: "/error",
      component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
    },
    {
      path: "/user",
      component: () => import(/* webpackChunkName: "user" */ "./views/user"),
      redirect: "/user/login",
      children: [
        {
          path: "login",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/Login"),
        },
        {
          path: "register",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/Register"),
        },
        {
          path: "forgot-password",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/ForgotPassword"),
        },
      ],
    },
    {
      path: "*",
      component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
    },
  ]
}

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
  hashbang: false,
  history: true,
});

export default router;
