import {
  getSalesCount,
  getSalesNSU,
  salesTotalPerEstablishment,
} from "@/services/venda-service";
import { generateFilterQuery } from "../../utils/sales";
import { zeroPad, mapCreditCardFlags } from "../../utils/sales";

const state = {
  filters: {
    startDate: new Date(
      new Date().getYear() + 1900,
      new Date().getMonth(),
      new Date().getDate() - 2
    ),
    endDate: new Date(),
    terminal: "Todos",
    saleType: "Todos",
    saleStatus: "Todos",
    creditCardFlag: 'Todos',
    nsu: "Todos",
    saleCapture: "Todos",
    page: 1,
    limit: 20
  },
  totalRows: 0,
  exportType: "pdf",
  salesList: [],
  salesListExport: [],
  totalizers: {},
  isFetching: false,
};

const mutations = {
  updateFilters(state, payload) {
    state.filters = { ...state.filters, ...payload };
  },
  setTotalRows(state, payload) {
    state.totalRows = payload;
  },
  setExportType(state, payload) {
    state.exportType = payload;
  },
  setSalesList(state, payload) {
    state.salesList = payload;
  },
  setTotalizers(state, payload) {
    state.totalizers = payload;
  },
  setIsFetching(state, payload) {
    state.isFetching = payload;
  },
  setSalesListExport(state, payload) {
    state.salesListExport = payload;
  }
};

const actions = {
  async getSalesList({ commit, state }, { estabelecimento, currentUser }) {
    let filter = generateFilterQuery(state.filters, estabelecimento);

    const totalResponse = await getSalesCount(currentUser, filter);
    commit("setTotalRows", totalResponse.data.total_registros);

    return getSalesNSU(currentUser, filter, "").then((response) => {
      let newValues = response.data.data.map((item) => ({
        nsu: item.nsu,
        bandeira: item.bandeira,
        data_venda: item.data_venda,
        status_venda: item.status_venda,
        captura: item.captura,
        natureza: item.natureza,
        tipo: item.tipo,
        valor_venda: item.valor_venda,
        valor_bruto: item.valor_bruto,
        valor_comissao: item.valor_comissao,
        valor_liquido: item.valor_liquido,
        valor_recebido: item.valor_recebido,
        conciliado: item.conciliado,
        ancora_2: item.ancora_2,
        autorizacao: String(zeroPad(item.autorizacao, 6)),
        parcela_plano: String(item.parcela_plano),
        plano: item.plano,
        data_previsao_cliente: item.data_previsao_cliente,
        terminal_serial: item.terminal_serial + ".",
        detalhes: '<i size="sm" style="font-size: 2em" class="iconsminds-add btn-action-table history mr-2"></i>',
      }));
      commit("setSalesList", newValues);
    });
  },

  async getSalesListExport(
    { commit, state },
    { estabelecimento, currentUser }
  ) {
    let filter = generateFilterQuery(state.filters, estabelecimento);
    return getSalesNSU(currentUser, filter, "xls").then((response) => {
      let newValues = response.data.data.map((item) => ({
        nsu: item.nsu,
        bandeira: mapCreditCardFlags(item.bandeira),
        data_venda: item.data_venda,
        captura: item.captura,
        natureza: item.natureza,
        tipo: item.tipo,
        valor_venda:
          String(item.parcela_plano).slice(0, 2) === "1/"
            ? item.valor_venda
            : "",
        valor_bruto: item.valor_bruto,
        valor_comissao: item.valor_comissao,
        valor_liquido: item.valor_liquido,
        valor_recebido: item.valor_recebido,
        conciliado: item.conciliado,
        autorizacao: String(zeroPad(item.autorizacao, 6)),
        parcela_plano: String(item.parcela_plano),
        data_previsao_cliente: item.data_previsao_cliente,
        terminal_serial: item.terminal_serial + ".",
        cpf_cnpj: item.cpf_cnpj,
        razao_social: item.razao_social
      }));
      commit("setSalesListExport", newValues);
    });
  },

  async getTotalizers({ commit, state }, { estabelecimento, currentUser }) {
    let filter = generateFilterQuery(state.filters, estabelecimento);
    const response = await salesTotalPerEstablishment(currentUser, filter);
    commit("setTotalizers", response.data);
  },
  async handleGetLists({ commit, dispatch }, { estabelecimento, currentUser }) {
    commit("setIsFetching", true);
    await dispatch("getSalesList", { estabelecimento, currentUser });
    await dispatch("getTotalizers", { estabelecimento, currentUser });
    commit("setIsFetching", false);
  },
};

const getters = {
  filters: (state) => state.filters,
  exportType: (state) => state.exportType,
  salesList: (state) => state.salesList,
  totalizers: (state) => state.totalizers,
  isFetching: (state) => state.isFetching,
  salesListExport: (state) => state.salesListExport,
  totalRows: (state) => state.totalRows,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
